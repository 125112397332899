import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

// Tools
import { toast, ToastContainer } from "react-toastify";
import _ from "lodash";
import moment from "moment";
import { json2excel } from "js2excel";
import { Carousel } from "react-responsive-carousel";
import { Lightbox } from "react-modal-image";

// Material
import MaterialTable, {
  MTableBody,
  MTableBodyRow,
  MTableEditRow,
  MTableFilterRow,
  MTableToolbar,
} from "material-table";
import { Button, Chip, TablePagination, Checkbox } from "@material-ui/core";
import { ArrowDownward, ArrowUpward, Check, Close, HighlightOff, GetApp } from "@material-ui/icons";

import axios from "../../axios-sw";
import { tableIcons, titleTemplate } from "../../shared/tableVariables";

import {
  authRedirect,
  catchResponse,
  createSingleColumnFilterVal,
  createSortPath,
  getAndConvertULRParams,
  getFilterPath,
  getSortPath,
  nonAdminRedirect,
  formatDate,
} from "../../shared/utility";
import { checkDateRange, sortByDate } from "../../shared/tables/helperTables";

import Spinner from "../../components/UI/Spinner/Spinner";
import SearchInput from "../../components/UI/SearchInput/SearchInput";
import ProgressCircular from "../../components/UI/ProgressCircular/ProgressCircular";
import ColumnsMultiSelect from "../../components/System/ColumnsMultiSelect/ColumnsMultiSelect";
import { HotKeys } from "../../components/System/HotKeys/HotKeys";
import FilterInput from "../../components/System/FilterInput/FilterInput";
import FilterDateRange from "../../components/System/FilterDateRange/FilterDateRange";

import { sortingPhraseHelper } from "./table";
import classes from "./Catalog.module.scss";

class Catalog extends Component {
  columns = [
    { title: "ID", field: "id", editable: "never", hidden: true },
    {
      title: "Images",
      field: "images",
      sorting: false,
      filtering: false,
      editable: "never",
      render: (rowData) => {
        if (rowData.images !== null && rowData.images.length > 0) {
          const content = [];
          rowData.images.forEach((url, key) => {
            content.push(
              <div key={key}>
                <img src={url} alt={rowData.index} />
              </div>
            );
          });
          return (
            <Carousel
              width={200}
              showStatus={false}
              showThumbs={false}
              onClickItem={(e, image) => {
                this.setState({ selectedImage: image.props.children.props.src, openLightbox: true });
              }}
            >
              {content}
            </Carousel>
          );
        }
        return "";
      },
    },
    {
      title: "Index",
      field: "index",
      editable: "never",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
    {
      title: "Style name",
      field: "style_name",
      editable: "never",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
    {
      title: "Sex",
      field: "sex",
      editable: "never",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
    {
      title: "Product name",
      field: "product_name",
      editable: "never",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
    {
      title: "Description",
      field: "description",
      editable: "never",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
      render: (rowData) => {
        return (
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: rowData.description,
            }}
          />
        );
      },
    },
    {
      title: "Composition",
      field: "short_description",
      editable: "never",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
      render: (rowData) => {
        return (
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: rowData.short_description,
            }}
          />
        );
      },
    },
    {
      title: "Store price",
      field: "store_price",
      editable: "never",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
    {
      title: "Season",
      field: "season",
      editable: "never",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
    {
      title: "Order number",
      field: "order_number",
      editable: "never",
      type: "text",
      render: (rowData) => {
        return (
          <div>
            <Link to={"/orders/" + rowData.order_id}>{rowData.order_number}</Link>
          </div>
        );
      },
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
    {
      title: "Warehouse",
      field: "warehouse",
      editable: "never",
      type: "dateType",
      filterComponent: (term) => {
        const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
        return <FilterDateRange onChange={onChange} />;
      },
      customFilterAndSearch: (term, rowData) => {
        return checkDateRange(term, rowData.warehouse);
      },
      customSort: (a, b, c, d) => sortByDate(a, b, d, "warehouse"),
    },
    {
      title: "Quantity",
      field: "quantity",
      editable: "never",
      filtering: false,
      sorting: false,
      type: "number",
      headerStyle: {
        textAlign: "right",
        paddingRight: "0",
        paddingLeft: "50px",
      },
      cellStyle: {
        textAlign: "right",
      },
      filterCellStyle: {
        paddingRight: "0",
      },
    },
    {
      title: "Designer",
      field: "designer",
      editable: "never",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
    {
      title: "Collection Ref.",
      field: "collection_ref",
      editable: "never",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
    {
      title: "Main color",
      field: "main_color",
      editable: "never",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
    {
      title: "Color for HZ",
      field: "colour_for_hz",
      editable: "never",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
    {
      title: "Graphic designer ref.",
      field: "graphic_designer_ref",
      editable: "never",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
  ];

  state = {
    columns: this.columns,
    columnsHidden: this.columns,
    currentStateColumns: null,
    data: [],
    filteredData: [],
    totalRow: 0,
    filterPhrase: "",
    filterByColumn: null,
    filterByConditional: { value: "all" },
    sortBy: null,
    sortingColumnsList: [],
    singleColumnFilterList: [],
    firstLoad: true,
    tableRef: React.createRef(),
    loaded: false,
    isModal: false,
    rowData: null,
    editable: {},
    actions: [],
    blockedSaveColumnsButton: false,
    syncingProductId: [],
    limit: 20,
    selectedReferences: [],
    filterComponentSet: false,
    exportChosen: false,
    selectedImage: null,
    openLightbox: false,
  };

  async componentDidMount() {
    const { page, limit, search, sortingColumnsList, singleColumnFilterList } = getAndConvertULRParams(
      this.props.location.search,
      sortingPhraseHelper
    );
    this.indexCatalog(page - 1, limit, search, sortingColumnsList, singleColumnFilterList);

    await this.getFilterColumns();
    this.setSortingForColumns();

    const table = this.state.tableRef.current;
    singleColumnFilterList.forEach((filter) => {
      const index = table.state.columns.findIndex((column) => column.field === filter.column.key);
      if (filter.type === "boolean") {
        if (filter.value.includes("true")) table.onFilterChange(index, "checked");
        else if (filter.value.includes("false")) table.onFilterChange(index, undefined);
        else table.onFilterChange(index, null);
      } else if (filter.type === "text") {
        table.state.columns[index].tableData.initialValue = filter.value
          .replace("LIKE", "")
          .replace("'%", "")
          .replace("%'", "")
          .trim();
      } else if (filter.type === "number") {
        table.state.columns[index].tableData.initialValue = filter.value.replace("=", "").trim();
      } else if (filter.type === "select") {
        if (filter.column.key === "barcode_status" && filter.value.includes(successQuery))
          table.onFilterChange(index, "success");
        if (
          filter.column.key === "barcode_status" &&
          filter.value.includes("IS NULL AND combinations.in_progress = true")
        )
          table.onFilterChange(index, "in_progress");
        if (
          filter.column.key === "barcode_status" &&
          filter.value.includes(`IS NOT NULL OR ${filter.column.path} <> ''`) &&
          !filter.value.includes(successQuery)
        )
          table.onFilterChange(index, "error");
        if (filter.column.key === "barcode_status" && filter.value.includes(readyQuery))
          table.onFilterChange(index, "ready");
      } else if (filter.type === "multiSelect") {
        let value = filter.value
          .split("LIKE")
          .join("")
          .split("'%")
          .join("")
          .split("%'")
          .join("")
          .split(`OR ${filter.column.path}`)
          .join(",")
          .trim();
        let valueArray = value.split(",").map((item) => item.trim());
        this.setState({ selectedReferences: valueArray });
        table.onFilterChange(index, valueArray);
      }
    });
  }

  indexCatalog = (
    page = 0,
    limit = 20,
    phrase = null,
    sortingColumnsList = [],
    singleColumnFilterList = [],
    currentStateColumns = null
  ) => {
    const sortPath = getSortPath(sortingColumnsList);
    const filterPath = getFilterPath(singleColumnFilterList);
    const currentSeasons = this.props.currentSeasons ? this.props.currentSeasons.map((season) => season.value) : [];
    let advencedFilterByColumn = null;
    let advencedFilterByConditional = { value: "all" };
    const tableColumns = this.columns;

    const table = this.state.tableRef.current;
    axios
      .get(`/catalog/${page}/${limit}.json`, {
        params: {
          page: page,
          limit: limit,
          phrase: phrase,
          sortPath: sortPath,
          filterPath: filterPath,
          currentSeasons: currentSeasons,
        },
        headers: { Authorization: `Bearer ${this.props.token}` },
      })
      .then((res) => {
        table.state.orderBy = -1;
        table.state.orderDirection = "";
        table.dataManager.orderBy = -1;
        table.dataManager.orderDirection = "";

        const advencedFilterIndex = singleColumnFilterList.findIndex((filter) => filter.type === "advenced");
        if (advencedFilterIndex !== -1) {
          const column = tableColumns.filter(
            (column) => column.field === singleColumnFilterList[advencedFilterIndex].column.key
          );
          advencedFilterByColumn = { label: column[0].title, value: column[0].field };

          if (
            singleColumnFilterList[advencedFilterIndex].value.includes("<> '' AND") ||
            singleColumnFilterList[advencedFilterIndex].value.includes("<> STR_TO_DATE(\"\",'%m-%d-%y') AND")
          )
            advencedFilterByConditional = {
              label: "Full Field",
              value: "full",
            };
          else if (
            singleColumnFilterList[advencedFilterIndex].value.includes('="" OR') ||
            singleColumnFilterList[advencedFilterIndex].value.includes("= STR_TO_DATE(\"\",'%m-%d-%y') OR")
          )
            advencedFilterByConditional = {
              label: "Empty Field",
              value: "empty",
            };
          else if (singleColumnFilterList[advencedFilterIndex].value.includes('LIKE "%%" OR'))
            advencedFilterByConditional = {
              label: "All Field",
              value: "all",
            };
        }

        const columnSettings = JSON.parse(window.localStorage.getItem(`user_catalog_${this.props.user_id}`));
        let columnsHidden = [];
        if (currentStateColumns) {
          columnsHidden = currentStateColumns;
        } else if (columnSettings && !currentStateColumns) {
          columnSettings.forEach((column) => {
            this.state.columns.forEach((item) => {
              if (column.field === item.field) {
                columnsHidden = [...columnsHidden, item];
              }
            });
          });
        } else {
          columnsHidden = this.state.columns;
        }

        this.setState({
          columnsHidden: columnsHidden,
          data: res.data.combinations,
          filteredData: res.data.combinations,
          totalRow: res.data.total,
          page: page,
          limit: limit,
          filterPhrase: phrase,
          sortingColumnsList: sortingColumnsList,
          singleColumnFilterList: singleColumnFilterList,
          loaded: true,
          filterByColumn: advencedFilterByColumn,
          filterByConditional: advencedFilterByConditional,
          filterComponentSet: true,
        });
      })
      .catch((err) => {
        this.setState({ loaded: true });
        catchResponse(err);
      });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.currentSeasons !== prevProps.currentSeasons) {
      this.setState({ data: [], filteredData: [], loaded: false });
      this.indexCatalog(
        0,
        this.state.limit,
        this.state.filterPhrase,
        this.state.sortingColumnsList,
        this.state.singleColumnFilterList
      );
    }
  }

  getFilterColumns = () => {
    const { currentStateColumns } = this.state;
    const columnSettings = JSON.parse(window.localStorage.getItem(`user_catalog_${this.props.user_id}`));
    let columnsHidden = [];

    if (currentStateColumns) {
      columnsHidden = currentStateColumns;
    } else if (columnSettings && !currentStateColumns) {
      columnSettings.forEach((column) => {
        this.state.columns.forEach((item) => {
          if (column.field === item.field) {
            columnsHidden = [...columnsHidden, item];
          }
        });
      });
    } else {
      columnsHidden = this.state.columns;
    }
    this.setState({ columnsHidden: columnsHidden });
  };

  setSortingForColumns = () => {
    const sortLabelsDOM = document.querySelectorAll(".MuiTableSortLabel-root");
    sortLabelsDOM.forEach((label) => {
      label.addEventListener("click", () => {
        let sortingColumnsList = this.state.sortingColumnsList;
        const labelName = label.querySelector("div").innerHTML.trim();
        const labelField = this.state.columns.find((column) => column.title === labelName).field;

        let indexIsExist = this.state.sortingColumnsList.findIndex((column) => column.sortBy.key === labelField);

        if (indexIsExist !== -1) {
          if (sortingColumnsList[indexIsExist].order === "DESC") sortingColumnsList.splice(indexIsExist, 1);
          else if (sortingColumnsList[indexIsExist].order === "ASC") sortingColumnsList[indexIsExist].order = "DESC";
          else if (!sortingColumnsList[indexIsExist].order) sortingColumnsList[indexIsExist].order = "ASC";
          this.setState({ sortingColumnsList: sortingColumnsList });
        } else {
          const sqlColumn = sortingPhraseHelper.filter((phrase) => phrase.key === labelField);
          sortingColumnsList = [...sortingColumnsList, { sortBy: sqlColumn[0], order: "ASC" }];
          this.setState({ sortingColumnsList: sortingColumnsList });
        }

        this.goPage(
          0,
          this.state.limit,
          this.state.filterPhrase,
          sortingColumnsList,
          this.state.singleColumnFilterList
        );
      });
    });
  };

  setSelectedColumns = (columns) => {
    let selectedColumns = [];

    columns.forEach((column) => {
      this.state.columns.forEach((item) => {
        if (column.value === item.field) {
          selectedColumns = [...selectedColumns, item];
        }
      });
    });

    this.setState({
      columnsHidden: selectedColumns,
      currentStateColumns: selectedColumns,
    });
  };

  handleSearch = (val) => {
    let convertVal = val.split("'").join("");

    const table = this.state.tableRef.current;
    const singleColumnFilterList = [];
    table.state.columns.forEach((column) => {
      column.tableData.initialValue = "";
    });
    this.goPage(0, this.state.limit, convertVal.toUpperCase(), this.state.sortingColumnsList, singleColumnFilterList);
    this.setState({ filterPhrase: convertVal.toUpperCase(), singleColumnFilterList: singleColumnFilterList });
  };

  goPage = (page = 0, limit = 20, search = null, sortingColumnsList = [], singleColumnFilterList = []) => {
    this.setState({ loaded: false, filteredData: [], data: [] });
    const searchVal = search && search.trim().length > 0 ? `&search=${search}` : "";

    const sortByVal = createSortPath(sortingColumnsList);
    const singleColumnFilterVal = createSingleColumnFilterVal(singleColumnFilterList);

    this.props.history.push(`/catalog?page=${page + 1}&limit=${limit}${searchVal}${sortByVal}${singleColumnFilterVal}`);
    this.indexCatalog(page, limit, search, sortingColumnsList, singleColumnFilterList, this.state.currentStateColumns);
  };

  closeLightbox = () => {
    this.setState({ selectedImage: null, openLightbox: false });
  };

  render() {
    let emptyDataMessage;
    if (!this.state.loaded) {
      emptyDataMessage = <Spinner />;
    } else {
      emptyDataMessage = "No data to display";
    }

    return (
      <>
        <Helmet>
          <title>Catalog | Carry System</title>
        </Helmet>

        {!this.state.loaded && <div className={"overlay"} />}
        {authRedirect(this.props.token)}
        {nonAdminRedirect(this.props.user_id, this.props.role, true)}

        {this.state.redirection}
        <ToastContainer />
        <HotKeys />

        {this.state.openLightbox && (
          <Lightbox medium={this.state.selectedImage} large={this.state.selectedImage} onClose={this.closeLightbox} />
        )}

        {this.state.limit !== null && (
          <MaterialTable
            tableRef={this.state.tableRef}
            title={titleTemplate("Catalog")}
            localization={{
              body: {
                emptyDataSourceMessage: emptyDataMessage,
                editRow: {
                  cancelTooltip: "Back",
                },
              },
            }}
            icons={tableIcons}
            columns={this.state.columnsHidden}
            data={this.state.filteredData}
            actions={this.state.actions}
            style={{ width: "100%" }}
            options={{
              search: false,
              pageSize: this.state.limit,
              filtering: true,
              selection: false,
              pageSizeOptions: [20, 50, 100],
            }}
            onChangeRowsPerPage={(pages) => {
              this.setState({ limit: pages, loaded: false, data: [], filteredData: [] });
              this.indexCatalog(
                0,
                pages,
                this.state.filterPhrase,
                this.state.sortingColumnsList,
                this.state.singleColumnFilterList
              );
            }}
            components={{
              Body: (props) => {
                return (
                  <MTableBody
                    {...props}
                    onFilterChanged={(columnId, value) => {
                      const columnName = this.state.columnsHidden[columnId].field;
                      let singleColumnFilterList = this.state.singleColumnFilterList;
                      let indexIsExist = this.state.singleColumnFilterList.findIndex(
                        (filter) => filter.column.key === columnName
                      );
                      const sqlColumn = sortingPhraseHelper.filter((phrase) => phrase.key === columnName);
                      const table = this.state.tableRef.current;

                      if (this.state.columnsHidden[columnId].type === "dateType") {
                        if (indexIsExist !== -1) {
                          if (value.isEmpty) {
                            singleColumnFilterList[indexIsExist].value = "IS NULL";
                          } else if (value.isFull) {
                            singleColumnFilterList[indexIsExist].value = "IS NOT NULL";
                          } else {
                            const start = moment(value.start).format("YYYY-MM-DD");
                            const end = moment(value.end).format("YYYY-MM-DD");
                            singleColumnFilterList[indexIsExist].value = `BETWEEN "${start}" AND "${end}"`;
                          }
                        } else {
                          if (value.isEmpty) {
                            singleColumnFilterList = [
                              ...singleColumnFilterList,
                              {
                                column: sqlColumn[0],
                                value: "IS NULL",
                                type: "dateType",
                              },
                            ];
                          } else if (value.isFull) {
                            singleColumnFilterList = [
                              ...singleColumnFilterList,
                              {
                                column: sqlColumn[0],
                                value: "IS NOT NULL",
                                type: "dateType",
                              },
                            ];
                          } else {
                            const start = moment(value.start).format("YYYY-MM-DD");
                            const end = moment(value.end).format("YYYY-MM-DD");
                            singleColumnFilterList = [
                              ...singleColumnFilterList,
                              {
                                column: sqlColumn[0],
                                value: `BETWEEN "${start}" AND "${end}"`,
                                type: "dateType",
                              },
                            ];
                          }
                        }
                      } else if (this.state.columnsHidden[columnId].type === "text") {
                        if (indexIsExist !== -1) {
                          singleColumnFilterList[indexIsExist].value = `LIKE '%${value}%'`;
                          table.state.columns[columnId].tableData.initialValue = value;
                        } else {
                          singleColumnFilterList = [
                            ...singleColumnFilterList,
                            {
                              column: sqlColumn[0],
                              value: `LIKE '%${value}%'`,
                              type: "text",
                            },
                          ];
                          table.state.columns[columnId].tableData.initialValue = value;
                        }
                      } else if (this.state.columnsHidden[columnId].type === "boolean") {
                        if (indexIsExist !== -1) {
                          if (value === "checked") {
                            singleColumnFilterList.splice(indexIsExist, 1);
                            table.onFilterChange(columnId, null);
                          } else if (value === "unchecked") {
                            singleColumnFilterList[indexIsExist].value = `=false OR ${sqlColumn[0].path} IS NULL`;
                            table.onFilterChange(columnId, undefined);
                          } else if (!value) {
                            singleColumnFilterList[indexIsExist].value = "=true";
                            table.onFilterChange(columnId, "checked");
                          }
                        } else {
                          singleColumnFilterList = [
                            ...singleColumnFilterList,
                            {
                              column: sqlColumn[0],
                              value: "=true",
                              type: "boolean",
                            },
                          ];
                          table.onFilterChange(columnId, "checked");
                        }
                      } else if (this.state.columnsHidden[columnId].type === "number") {
                        const convertValue = value ? parseFloat(value.replace(/\s/g, "").replace(",", ".")) : 0;

                        if (indexIsExist !== -1) {
                          singleColumnFilterList[indexIsExist].value = `= ${convertValue}`;
                          table.state.columns[columnId].tableData.initialValue = convertValue;
                        } else {
                          singleColumnFilterList = [
                            ...singleColumnFilterList,
                            {
                              column: sqlColumn[0],
                              value: `= ${convertValue}`,
                              type: "number",
                            },
                          ];
                          table.state.columns[columnId].tableData.initialValue = convertValue;
                        }
                      } else if (this.state.columnsHidden[columnId].type === "select") {
                        if (value === "all") value = `LIKE "%%" OR ${sqlColumn[0].path} IS NULL`;
                        else if (value === "empty" || value === "unadded")
                          value = `="" OR ${sqlColumn[0].path} IS NULL`;
                        else if (value === "reference" || value === "added")
                          value = `<> '' AND ${sqlColumn[0].path} IS NOT NULL AND ${sqlColumn[0].path}`;
                        else value = `LIKE "%${value}%"`;

                        if (indexIsExist !== -1) {
                          singleColumnFilterList[indexIsExist].value = value;
                          table.state.columns[columnId].tableData.initialValue = value;
                        } else {
                          singleColumnFilterList = [
                            ...singleColumnFilterList,
                            {
                              column: sqlColumn[0],
                              value: value,
                              type: "select",
                            },
                          ];
                          table.state.columns[columnId].tableData.initialValue = value;
                        }
                      } else if (this.state.columnsHidden[columnId].type === "multiSelect") {
                        if (indexIsExist !== -1) {
                          let multiFiltersValues = "";
                          value.forEach((filter, index) => {
                            multiFiltersValues +=
                              index === 0
                                ? `LIKE '%${value[index]}%'`
                                : ` OR ${sqlColumn[0].path} LIKE '%${value[index]}%'`;
                          });
                          singleColumnFilterList[indexIsExist].value = multiFiltersValues;
                          table.onFilterChange(columnId, value);
                        } else {
                          singleColumnFilterList = [
                            ...singleColumnFilterList,
                            {
                              column: sqlColumn[0],
                              value: `LIKE '%${value}%'`,
                              type: "multiSelect",
                            },
                          ];
                          table.onFilterChange(columnId, value);
                        }
                      }

                      this.setState({
                        singleColumnFilterList: singleColumnFilterList,
                        filterPhrase: "",
                        filterByConditional: { value: "all" },
                        filterByColumn: null,
                      });
                      this.goPage(0, this.state.limit, "", this.state.sortingColumnsList, singleColumnFilterList);
                    }}
                  />
                );
              },
              Pagination: (props) => {
                return (
                  <TablePagination
                    {...props}
                    rowsPerPage={this.state.limit}
                    count={this.state.totalRow}
                    page={this.state.page}
                    onChangePage={(e, page) => {
                      this.state.tableRef.current.onChangePage(e, page);
                      this.setState({ page: page });
                      this.goPage(
                        page,
                        this.state.limit,
                        this.state.filterPhrase,
                        this.state.sortingColumnsList,
                        this.state.singleColumnFilterList
                      );
                    }}
                    onRowsPerPageChange={(event) => {
                      // ? props.onRowsPerPageChange(event);
                      this.setState({ limit: event.target.value });
                      this.goPage(
                        this.state.page,
                        event.target.value,
                        this.state.filterPhrase,
                        this.state.sortingColumnsList,
                        this.state.singleColumnFilterList
                      );
                    }}
                  />
                );
              },
              FilterRow: (props) => {
                return <MTableFilterRow className="filterRow" {...props} />;
              },
              Row: (props) => <MTableBodyRow className={props.data.styles} {...props} />,
              EditRow: (props) => <MTableEditRow className="editingRow uppercase" {...props} />,
              Toolbar: (props) => {
                const table = this.state.tableRef.current;
                return (
                  <div className="mainToolbar">
                    <MTableToolbar {...props} />

                    <Chip
                      variant="outlined"
                      onClick={() => {
                        this.goPage();
                        this.props.history.push(`/catalog?page=${1}&limit=${20}`);

                        table.state.columns.forEach((column, index) => {
                          if (column.field === "reference") {
                            table.state.columns[index].tableData.filterValue = [];
                            column.tableData.initialValue = [];
                          } else {
                            table.onFilterChange(index, null);
                            table.state.columns[index].tableData.filterValue = null;
                            column.tableData.initialValue = "";
                          }
                        });
                      }}
                      style={{ marginLeft: 24, marginTop: 5 }}
                      avatar={<HighlightOff />}
                      label="Clear filters"
                    />

                    {/* Eksportowanie danych z tabeli */}
                    <div className={classes.exportToExcelWrapper}>
                      <Chip
                        variant="outlined"
                        style={{ marginLeft: 24, marginTop: 10 }}
                        avatar={this.state.loaded ? <GetApp style={{ padding: 5 }} /> : <ProgressCircular size={20} />}
                        label="Export data to Excel"
                        onClick={() => {
                          const startTime = Date.now();
                          this.setState({ loaded: false, filteredData: [] });
                          const currentSeasons = this.props.currentSeasons.map((season) => season.value);

                          const { search, sortingColumnsList, singleColumnFilterList } = getAndConvertULRParams(
                            this.props.location.search,
                            sortingPhraseHelper
                          );
                          const sortPath = getSortPath(sortingColumnsList);
                          const filterPath = getFilterPath(singleColumnFilterList);

                          axios
                            .get(`/catalog/export.json`, {
                              params: {
                                phrase: search,
                                sortPath: sortPath,
                                filterPath: filterPath,
                                currentSeasons: currentSeasons,
                              },
                              headers: { Authorization: `Bearer ${this.props.token}` },
                            })
                            .then((res) => {
                              console.log("Response time: " + (Date.now() - startTime) / 1000 + " s");
                              const filteredProductsData = res.data.combinations.map((combination) => {
                                return {
                                  id: combination.id,
                                  index: combination.index,
                                  style_name: combination.style_name,
                                  sex: combination.sex,
                                  product_name: combination.product_name,
                                  description: combination.description,
                                  short_description: combination.short_description,
                                  store_price: combination.store_price,
                                  season: combination.season,
                                  order_number: combination.order_number,
                                  quantity: combination.quantity,
                                  designer: combination.designer,
                                  collection_ref: combination.collection_ref,
                                  main_color: combination.main_color,
                                  colour_for_hz: combination.colour_for_hz,
                                  warehouse: combination.warehouse,
                                  graphic_designer_ref: combination.graphic_designer_ref,
                                };
                              });

                              // Ustalenie kolumn
                              if (this.state.exportChosen) {
                                const exportColumns = this.state.columnsHidden.map((column) => {
                                  return column.field;
                                });

                                filteredSyncProductsData = filteredSyncProductsData.map((row) => {
                                  const toDelete = exportColumns;
                                  const filtered = Object.keys(row)
                                    .filter((key) => toDelete.includes(key))
                                    .reduce((obj, key) => {
                                      obj[key] = row[key];
                                      return obj;
                                    }, {});

                                  return filtered;
                                });
                              }

                              json2excel({
                                data: filteredProductsData,
                                name: "catalog_" + formatDate(Date.now()),
                                formateDate: "dd/mm/yyyy",
                              });

                              this.setState({ loaded: true, filteredData: this.state.data });
                            })
                            .catch((err) => {
                              catchResponse(err);
                            });
                        }}
                      />
                      <div className={classes.checkboxWrapper}>
                        <Checkbox
                          checked={this.state.exportChosen}
                          size={"small"}
                          onChange={() => this.setState({ exportChosen: !this.state.exportChosen })}
                          color="primary"
                        />
                        <span>Export only chosen columns</span>
                      </div>
                    </div>

                    {/* Generowanie tagu wyszukania */}
                    {this.state.filterPhrase && this.state.filterPhrase.trim().length > 0 && (
                      <Chip
                        style={{ marginLeft: 24, marginTop: 10 }}
                        onDelete={() => {
                          this.goPage(
                            0,
                            this.state.limit,
                            "",
                            this.state.sortingColumnsList,
                            this.state.singleColumnFilterList
                          );
                          this.setState({ filterPhrase: "" });
                        }}
                        label={`search: ${this.state.filterPhrase}`}
                        color="primary"
                      />
                    )}

                    {/* Generowanie tagów filtrów kolumn */}
                    {this.state.singleColumnFilterList.map((filter, key) => {
                      let value;
                      let icon = "";
                      if (filter.type === "dateType") value = filter.value.replace("BETWEEN", "").replace("AND", "-");
                      else if (filter.type === "text")
                        value = filter.value.replace("LIKE", "").replace("'%", "").replace("%'", "");
                      else if (filter.type === "select")
                        value = filter.value.replace("LIKE", "").replace("'%", "").replace("%'", "");
                      else if (filter.type === "multiSelect")
                        value = filter.value
                          .split("LIKE")
                          .join("")
                          .split("'%")
                          .join("")
                          .split("%'")
                          .join("")
                          .split(`OR ${filter.column.path}`)
                          .join(",");
                      else if (filter.type === "number") value = filter.value.replace("=", "");
                      else if (filter.type === "boolean") {
                        if (filter.value.includes("true")) {
                          value = "checked";
                          icon = <Check />;
                        } else {
                          value = "unchecked";
                          icon = <Close />;
                        }
                      }
                      return (
                        <Chip
                          key={key}
                          style={{ marginLeft: 24, marginTop: 10 }}
                          onDelete={() => {
                            if (filter.column.key === "reference") {
                              this.setState({ selectedReferences: [] });
                            }
                            const singleColumnFilterList = this.state.singleColumnFilterList.filter(
                              (item) => item.column.key !== filter.column.key
                            );
                            this.goPage(
                              0,
                              this.state.limit,
                              this.state.filterPhrase,
                              this.state.sortingColumnsList,
                              singleColumnFilterList
                            );
                            this.setState({ singleColumnFilterList: singleColumnFilterList });
                            const index = table.state.columns.findIndex((item) => item.field === filter.column.key);
                            if (index !== -1) {
                              if (table.state.columns[index].field === "reference") {
                                table.state.columns[index].tableData.initialValue = [];
                              } else {
                                table.state.columns[index].tableData.initialValue = "";
                                table.onFilterChange(index, null);
                              }
                            }
                          }}
                          label={`${this.state.columns
                            .find((item) => item.field === filter.column.key)
                            .title.toUpperCase()}: ${value.toUpperCase()}`}
                          icon={icon}
                          variant="outlined"
                        />
                      );
                    })}

                    {/* Generowanie tagów sortowania */}
                    {this.state.sortingColumnsList.map((column) => {
                      let icon = "";

                      if (column.order === "ASC") icon = <ArrowDownward />;
                      if (column.order === "DESC") icon = <ArrowUpward />;

                      return (
                        <Chip
                          style={{ marginLeft: 24, marginTop: 10 }}
                          onDelete={() => {
                            const sortingColumnsList = this.state.sortingColumnsList.filter(
                              (item) => item.sortBy.key !== column.sortBy.key
                            );
                            this.goPage(
                              0,
                              this.state.limit,
                              this.state.filterPhrase,
                              sortingColumnsList,
                              this.state.singleColumnFilterList
                            );
                            this.setState({ sortingColumnsList: sortingColumnsList });
                          }}
                          label={`${column.sortBy.key.toUpperCase().replace("_", " ")}`}
                          icon={icon}
                        />
                      );
                    })}

                    <div className={classes.ColumnsMultiSelectWrapper}>
                      <ColumnsMultiSelect
                        onOpen={() =>
                          !this.state.blockedSaveColumnsButton && this.setState({ blockedSaveColumnsButton: true })
                        }
                        value={this.state.columnsHidden
                          .filter((column) => column.field !== "id")
                          .map((column) => {
                            return {
                              label: column.title,
                              value: column.field,
                              table: "catalog",
                            };
                          })}
                        options={this.columns
                          .filter((column) => column.field !== "id")
                          .map((column) => {
                            return {
                              label: column.title,
                              value: column.field,
                              table: "catalog",
                            };
                          })}
                        onChangeColumns={async (columns) => {
                          await this.setState({ columnsHidden: [], currentStateColumns: null });
                          await this.setSelectedColumns(columns);
                          this.setState({ blockedSaveColumnsButton: false });
                          this.setSortingForColumns();
                        }}
                      />
                      <Button
                        disabled={this.state.blockedSaveColumnsButton}
                        onClick={() => {
                          if (this.props.user_id) {
                            window.localStorage.setItem(
                              `user_catalog_${this.props.user_id}`,
                              JSON.stringify(this.state.columnsHidden)
                            );
                            toast.success("The selected columns have been saved!");
                            this.setState({ blockedSaveColumnsButton: true });
                          } else {
                            toast.error("You must be logged in!");
                          }
                        }}
                        variant={"outlined"}
                      >
                        SAVE
                      </Button>
                      <Button
                        onClick={async () => {
                          if (this.props.user_id) {
                            window.localStorage.removeItem(`user_catalog_${this.props.user_id}`);
                            await this.setState({
                              columnsHidden: this.state.columns,
                              currentStateColumns: null,
                            });
                            toast.success("Column settings have been removed!");
                            this.setSortingForColumns();
                          } else {
                            toast.error("You must be logged in!");
                          }
                        }}
                        color={"secondary"}
                        variant={"outlined"}
                      >
                        RESET
                      </Button>
                    </div>

                    <div className={classes.SearchWrapper}>
                      <SearchInput filterPhrase={this.state.filterPhrase} onChange={this.handleSearch} />
                    </div>
                  </div>
                );
              },
            }}
            editable={this.state.editable}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user_id: state.auth.user_id,
    ip_address: state.auth.ip_address,
    token: state.auth.token,
    role: state.auth.role,
    currentSeasons: state.systemInfo.currentSeasons,
  };
};

export default connect(mapStateToProps)(Catalog);
